<template>
    <div id="pushButtonAlertsHistoryList" class="baseTemplate">

        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">

                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="pushButtonAlertsHistory"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">

                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">

                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="alert_pushButtonAlertAlarmsListSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper" v-if="!isEmptyList">
                                    <button @click="acknowledgeAllForSite()" id="acknowledgeAllForSiteButton" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="la la-check kt-margin-t-1 kt-margin-r-5"></i>
                                        {{ $t("alarm_acknowledgeAll") }}
                                    </button>
                                    <button @click="deleteAllForSite()" id="deleteAllForSiteButton" class="btn btn-danger btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="la la-trash kt-margin-t-1 kt-margin-r-5"></i>
                                        {{ $t("geo_notif_deleteAll") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <div class="kt-portlet">
                                <div class="kt-portlet__body kt-portlet__body--fit">
                                    <div class="row row-no-padding row-col-separator-xl">
                                        <div class="col-md-12 col-lg-12 col-xl-3">
                                            <!--begin:: Widgets/Stats2-1 -->
                                            <div class="kt-widget1">
                                                <div class="kt-widget1__item">
                                                    <div class="kt-widget1__info" data-cy="home_widget_info_sites">
                                                        <h3 class="kt-widget1__title">{{ $t("common_total") }}</h3>
                                                        <span class="kt-widget1__desc">{{ $t("geo_notif_totalNumberOfNotif") }}</span>
                                                    </div>
                                                    <span class="kt-widget1__number kt-font-dark" style=" font-size: 2rem; ">{{ totalPushButtonAlertsHistory || 0 }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-3">
                                            <div class="kt-widget1">
                                                <div class="kt-widget1__item">
                                                    <div class="kt-widget1__info" data-cy="home_widget_info_sitesInProduction">
                                                        <h3 class="kt-widget1__title">{{ $t("geo_notif_unacknowledgeds") }}</h3>
                                                        <span class="kt-widget1__desc">{{ $t("geo_notif_numberOfUnackNotif") }}</span>
                                                    </div>
                                                    <span class="kt-widget1__number kt-font-success" style=" font-size: 2rem; ">{{ newNotificationsForSite }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-3">
                                            <!--begin:: Widgets/Stats2-3 -->
                                            <div class="kt-widget1">
                                                <div class="kt-widget1__item">
                                                    <div class="kt-widget1__info" data-cy="home_widget_info_Applications">
                                                        <h3 class="kt-widget1__title">{{ $t("geo_notif_acknowledgeds") }}</h3>
                                                        <span class="kt-widget1__desc">{{ $t("geo_notif_numberOfAckNotif") }}</span>
                                                    </div>
                                                    <span class="kt-widget1__number kt-font-brand" style=" font-size: 2rem; ">{{ acknowledgedNotificationsForSite }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-3">
                                            <div class="kt-widget1">
                                                <div class="kt-widget1__item">
                                                    <div class="kt-widget1__info" data-cy="home_widget_info_Applications">
                                                        <h3 class="kt-widget1__title">{{ $t("geo_notif_lastHour") }}</h3>
                                                        <span class="kt-widget1__desc">{{ $t("geo_notif_numberOfLastHourNotif") }}</span>
                                                    </div>
                                                    <span class="kt-widget1__number kt-font-danger" style=" font-size: 2rem; ">{{ lastHourNotificationsForSite }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M10.9630156,7.5 L11.0475062,7.5 C11.3043819,7.5 11.5194647,7.69464724 11.5450248,7.95024814 L12,12.5 L15.2480695,14.3560397 C15.403857,14.4450611 15.5,14.6107328 15.5,14.7901613 L15.5,15 C15.5,15.2109164 15.3290185,15.3818979 15.1181021,15.3818979 C15.0841582,15.3818979 15.0503659,15.3773725 15.0176181,15.3684413 L10.3986612,14.1087258 C10.1672824,14.0456225 10.0132986,13.8271186 10.0316926,13.5879956 L10.4644883,7.96165175 C10.4845267,7.70115317 10.7017474,7.5 10.9630156,7.5 Z" fill="#000000" />
                                                            <path d="M7.38979581,2.8349582 C8.65216735,2.29743306 10.0413491,2 11.5,2 C17.2989899,2 22,6.70101013 22,12.5 C22,18.2989899 17.2989899,23 11.5,23 C5.70101013,23 1,18.2989899 1,12.5 C1,11.5151324 1.13559454,10.5619345 1.38913364,9.65805651 L3.31481075,10.1982117 C3.10672013,10.940064 3,11.7119264 3,12.5 C3,17.1944204 6.80557963,21 11.5,21 C16.1944204,21 20,17.1944204 20,12.5 C20,7.80557963 16.1944204,4 11.5,4 C10.54876,4 9.62236069,4.15592757 8.74872191,4.45446326 L9.93948308,5.87355717 C10.0088058,5.95617272 10.0495583,6.05898805 10.05566,6.16666224 C10.0712834,6.4423623 9.86044965,6.67852665 9.5847496,6.69415008 L4.71777931,6.96995273 C4.66931162,6.97269931 4.62070229,6.96837279 4.57348157,6.95710938 C4.30487471,6.89303938 4.13906482,6.62335149 4.20313482,6.35474463 L5.33163823,1.62361064 C5.35654118,1.51920756 5.41437908,1.4255891 5.49660017,1.35659741 C5.7081375,1.17909652 6.0235153,1.2066885 6.2010162,1.41822583 L7.38979581,2.8349582 Z" fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">{{ $t("alert_pushButtonAlertHistory") }}</h3>
                                            </div>
                                            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                                                <button @click="onExportPushButtonAlertsHistory" id="geonotificationsHistory_exportGeoNotificationsHistoryFromXLSButton" class="exportXLSXButton btn btn-sm btn-outline-success kt-margin-l-10">
                                                    <i class="fa fa-file-excel kt-margin-r-5"></i>
                                                    {{ $t("common_exportFromXLSFile") }}
                                                </button>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="ALERT_LIST" mainTextKey="alert_emptyAlarmHistory" subTextKey="alert_emptyAlarmHistorySubLabel" imgName="empty_notifications.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="alerts_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "../utils/emptylist.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

const xlMomentDateFormat = commonVueHelper.getXlDateTimezoneFormat();

export default {
    data() {
        return {
            baseURL: process.env.VUE_APP_BACKEND_URL,
            siteId: this.$route.params.siteId,
            siteNotificationsTable: null,
            isEmptyList: false,
            searchedValue: "",
            authorization: "Bearer " + localStorage.getItem("token"),
            limit: null,
            offset: null,
            sort: null,
            field: null,
            isGetPushButtonAlarmsForSiteToExport: false,
            totalPushButtonAlertsHistory: 0,
            unAcknowledgedPushButtonAlertsHistory: 0,
            acknowledgedPushButtonAlertsHistory: 0,
            lastHourNotificationsAlertsHistory: 0
        };
    },
    created: function() {
        console.log("Component(PushButtonAlertsHistory)::created() - called");
        this.getPushButtonAlarmsForSite({ siteId: this.siteId, limit: this.limit || 50, offset: this.offset || 0 });
    },
    mounted: function() {
        console.log("Component(PushButtonAlertsHistory)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    beforeDestroy: function() {
        console.log("Component(PushButtonAlertsHistory)::beforeDestroy() - called");
        this.searchedValue = "";
        // Reset component data
        if (this.siteNotificationsTable) {
            this.siteNotificationsTable.destroy();
            this.siteNotificationsTable = null;
        }
    },
    destroyed: function() {
        console.log("Component(PushButtonAlertsHistory)::destroyed() - called");
        this.resetAlarmsState();
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentSitePushButtonAlarms: function(currentSitePushButtonAlarms) {
            console.log("Component(PushButtonAlertsHistory)::watch(currentSitePushButtonAlarms) - called with ", currentSitePushButtonAlarms);
            if (currentSitePushButtonAlarms && !this.isGetPushButtonAlarmsForSiteToExport) {
                this.initKtDataTable();
            }

            if (this.isGetPushButtonAlarmsForSiteToExport) {
                this.exportPushButtonAlertsHistoryModal();
                this.isGetPushButtonAlarmsForSiteToExport = false;
            }
        },
        user: function(user) {
            if (user) {
                console.log("Component(PushButtonAlertsHistory)::watch(user) called with :", user);
                this.initKtDataTable();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["user", "currentSitePushButtonAlarms"]),

        siteAlarms: function() {
            console.log("Component(PushButtonAlertsHistory)::computed(siteAlarms) - called ");
            if (this.currentSitePushButtonAlarms) {
                return this.currentSitePushButtonAlarms;
            }
            return [];
        },

        newNotificationsForSite: function() {
            let result = 0;
            if (this.unAcknowledgedPushButtonAlertsHistory) {
                result = parseInt(this.unAcknowledgedPushButtonAlertsHistory)
            }
            return result;
        },

        acknowledgedNotificationsForSite: function() {
            let result = 0;
            if (this.acknowledgedPushButtonAlertsHistory) {
                result = parseInt(this.acknowledgedPushButtonAlertsHistory);
            }
            return result;
        },

        lastHourNotificationsForSite: function() {
            let result = 0;
            if (this.lastHourNotificationsAlertsHistory) {
                // let nowMinusOneHour = moment().subtract(1, 'hours');
                // for (let alarm of this.currentSitePushButtonAlarms.data) {
                //     if (alarm && moment(alarm.issuedAt).isAfter(nowMinusOneHour)) {
                //         result += 1;
                //     }
                // }
                result = parseInt(this.lastHourNotificationsAlertsHistory);
            }
            return result;
        }

    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getPushButtonAlarmsForSite",
            "acknowledgeAlarm",
            "showDeleteModal",
            // "acknowledgePushButtonAlarms",
            // "deletePushButtonAlarms",
            "setSiteAlarm",
            "resetAlarmsState",
            "acknowledgeAllPushButtonAlarmsForSite",
            "deleteAllPushButtonAlarmsForSite"
        ]),

        acknowledgeAllForSite() {
            // this.acknowledgePushButtonAlarms({ siteId: this.siteId, isAcknowledgeAllForSite: true });
            this.acknowledgeAllPushButtonAlarmsForSite(this.siteId);
        },

        deleteAllForSite() {
            // this.deletePushButtonAlarms({ siteId: this.siteId, isDeleteAllForSite: true });
            this.deleteAllPushButtonAlarmsForSite(this.siteId);
        },

        onEditEventType: function(eventTypeId) {
            this.$router.push({
                name: "editPushButtonEventType",
                params: { siteId: this.siteId, eventTypeId: eventTypeId }
            });
        },

        onAcknowledgeAlarm: function(alarmId) {
            this.acknowledgeAlarm({ alarmId: alarmId, siteId: this.siteId, limit: this.limit, offset: this.offset });
        },

        onEditAsset: function(assetId) {
            this.$router.push({
                name: "editAsset",
                params: { assetId: assetId }
            });
        },

        registerEventsOnSiteNotificationsTable() {
            let self = this;

            console.log("Component(PushButtonAlertsHistory)::registerEventsOnSiteNotificationsTable - called");

            $("#alerts_datatable").on(
                "kt-datatable--on-layout-updated",
                () => {
                    $(".deleteNotificationButton")
                        .off()
                        .on("click", function() {
                            let alarmId = $(this).attr("data-alarmid");
                            let fulldesc = $(this).attr("data-fulldesc");
                            console.log("Component(PushButtonAlertsHistory)::registerEventsOnSiteNotificationsTable(deleteNotificationButton) clicked. AlarmId = " + alarmId);
                            self.showDeleteModal({
                                textContent: i18n.t("geo_notif_deleteNotifConfirmText", { fulldesc: fulldesc }),
                                actionName: "DELETE_ALARM",
                                params: {
                                    siteId: self.siteId,
                                    alarmId: alarmId,
                                    limit: self.limit,
                                    offset: self.offset
                                }
                            });
                        });
                    $(".acknowledgeButton")
                        .off()
                        .on("click", function() {
                            const alarmId = $(this).attr("data-alarmid");
                            console.log("Component(PushButtonAlertsHistory)::registerEventsOnSiteNotificationsTable(acknowledgeButton) clicked. AlarmId = " + alarmId);
                            // Hide and destroy element’s tooltip
                            $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                            // Go to edit vue
                            self.onAcknowledgeAlarm(alarmId);
                        });
                    $(".editAssetButton")
                        .off()
                        .on("click", function() {
                            const assetId = $(this).attr("data-assetid");
                            console.log("Component(PushButtonAlertsHistory)::registerEventsOnSiteNotificationsTable(editAssetButton) clicked. assetId = " + assetId);
                            // Hide and destroy element’s tooltip
                            $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                            // Go to edit vue of asset
                            self.onEditAsset(assetId);
                        });
                    $(".editEventTypeButton")
                        .off()
                        .on("click", function() {
                            const eventTypeId = $(this).attr("data-eventTypeId");
                            console.log("Component(PushButtonAlertsHistory)::registerEventsOnSiteNotificationsTable(editEventTypeButton) clicked.");
                            // Hide and destroy element’s tooltip
                            $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                            // Go to edit vue of event type
                            self.onEditEventType(eventTypeId);
                        });
                    // Force width pager size
                    setTimeout(() => {
                        $(".kt-datatable__pager-size").css("width", "80px");
                        // Init tooltips
                        $('[data-toggle="kt-tooltip"]').tooltip({
                            trigger: "hover"
                        });
                    }, 50);
                }
            );
        },

        onExportPushButtonAlertsHistory() {
            this.getPushButtonAlarmsForSite({ siteId: this.siteId });
            this.isGetPushButtonAlarmsForSiteToExport = true;
        },

        exportPushButtonAlertsHistoryModal() {
            // Call common vueHelper function
            let data = [];
            // Creation of table header
            let header = [
                {name: i18n.t("alert_pushButtonAlertExportDescription"), type: "STRING"},
                {name: i18n.t("alert_pushButtonAlertExportStatus"), type: "STRING"},
                {name: i18n.t("alert_pushButtonAlertExportAssetName"), type: "STRING"},
                {name: i18n.t("alert_pushButtonAlertExportPushEvent"), type: "STRING"},
                {name: i18n.t("alert_pushButtonAlertExportIssueAt"), type: "DATETIMEZONE"}
            ];

            if (this.siteAlarms) {
                this.siteAlarms.forEach(alarm => {
                    // Checks the Full Description
                    const assetName = alarm.assetName ? alarm.assetName : "-";
                    const pushEventTypeName = (alarm.pushEventTypeName && alarm.isDefaultPushEventType ? i18n.t("alert_pushEventType_"+alarm.pushEventTypeName) : alarm.pushEventTypeName );
                    const fullDescription = i18n.t("alarm_pushButtonHistoryFullDescription", {tagSerialNumber: alarm.tagSerialNumber, tagMacAddress: alarm.tagMacAddress, assetName: assetName, siteName: alarm.siteName, eventTypeName: pushEventTypeName});

                    // Checks if alarm is acknowledged
                    let notificationStatus = (alarm.isAcknowledged) ? i18n.t("geo_notif_acknowledged") : i18n.t("geo_notif_unacknowledged");

                    // Set the right date format
                    const issuedAt = moment(alarm.issuedAt).format(xlMomentDateFormat);

                    // Build data with all informations for this alarm
                    let alarmInfo = [fullDescription, notificationStatus, assetName, pushEventTypeName, issuedAt];

                    // Add in export data
                    data.push(alarmInfo);
                });
            }

            // Call common vueHelper function
            commonVueHelper.exportsDataToExcel(data, header, "alert-history", "alert-history");
        },

        formatSiteAlarms(siteAlarms) {
            const txtAcknowledged = i18n.t("geo_notif_acknowledged");
            const txtUnacknowledged = i18n.t("geo_notif_unacknowledged");

            // Prepare data
            let dataSource = [];

            if (siteAlarms) {
                dataSource = siteAlarms.map((alarm) => {
                    let customAlarm = {...alarm };

                    const translatedPushEventType = ( alarm.pushEventTypeName && alarm.isDefaultPushEventType ? i18n.t("alert_pushEventType_"+alarm.pushEventTypeName) : alarm.pushEventTypeName );
                    customAlarm.translatedEventType = translatedPushEventType;
                    customAlarm.translatedFullDescription = i18n.t("alarm_pushButtonHistoryFullDescription", { assetName: alarm.assetName, eventTypeName: translatedPushEventType});
                    if (alarm.isAcknowledged) {
                        customAlarm.notificationStatus = txtAcknowledged;
                    } else {
                        customAlarm.notificationStatus = txtUnacknowledged;
                    }
                    return customAlarm;
                });
            }
            return dataSource;
        },

        initKtDataTable() {

            const options = {
                data: {
                    type: "remote",
                    source: {
                        read: {
                            url: this.baseURL + "/web/sites/" + this.siteId + '/alarms/pushbuttonalerts',
                            method: "GET",
                            headers: {
                                Authorization: this.authorization
                            },
                            params: {
                                pagination: {},
                                query: {},
                            },
                            map: (response) => {
                                this.setSiteAlarm({ alarms: response.data });
                                this.limit = response.meta.perpage;
                                this.sort = response.meta.sort;
                                this.field = response.meta.field;
                                this.offset = (response.meta.page - 1) * this.limit;

                                this.totalPushButtonAlertsHistory = response.meta.total;
                                this.unAcknowledgedPushButtonAlertsHistory = response.meta.unAcknowledged;
                                this.acknowledgedPushButtonAlertsHistory = response.meta.acknowledged;
                                this.lastHourNotificationsAlertsHistory = response.meta.inLastHour;

                                const dataSource = this.formatSiteAlarms(response.data);
                                return commonVueHelper.preventHtmlInjection(dataSource);
                            }
                        }
                    },
                    saveState: {
                        webstorage: false
                    },
                    pageSize: 20,
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: !0,
                pagination: !0,
                columns: [
                    {
                        field: "translatedFullDescription",
                        title: i18n.t("geo_notif_description"),
                        default: "-",
                        textAlign: "center",
                        overflow: "visible",
                        autoHide: false,
                        template: function(row) {
                            return (`<span class="d-inline-block text-truncate" style="max-width: 110%;" data-toggle="kt-tooltip" data-placement="top" title="${row.translatedFullDescription}">${row.translatedFullDescription}</span>`)
                        }
                    },
                    {
                        field: "isAcknowledged",
                        title: i18n.t("geo_notif_notifStatus"),
                        textAlign: "center",
                        overflow: "visible",
                        autoHide: false,
                        template: function(row) {
                            if (row.isAcknowledged) {
                                return (
                                    '<span class="kt-badge kt-badge--brand kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-brand">' + row.notificationStatus + '</span>'
                                );
                            } else {
                                return (
                                    '<span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">' + row.notificationStatus + '</span>'
                                );
                            }
                        }
                    },
                    {
                        field: "assetName",
                        title: i18n.t("geo_notif_assetName"),
                        textAlign: "center",
                        template: function(row) {
                            if (row.asset) {
                                return (
                                    '\
                                    <div class="kt-user-card-v2">\
                                        <div class="kt-user-card-v2__details openResourceLink">\
                                            <a class="editAssetButton kt-link kt-user-card-v2__name" data-assetid="' + row.asset + '" href="javascript:;">' +
                                                row.assetName +
                                            '</a>\
                                        </div>\
                                    </div>\
                                    '
                                );
                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        field: "pushEventTypeName",
                        title: i18n.t("alert_pushButtonAlertEventType"),
                        textAlign: "center",
                        template: function(row) {
                            if (row.pushEventType) {
                                if (row.isDefaultPushEventType) {
                                    return (
                                        `<span class="kt-user-card-v2__details">` + row.translatedEventType + `</span>`
                                    );

                                } else {
                                    return (
                                        `<div class="kt-user-card-v2">
                                            <div class="kt-user-card-v2__details openResourceLink">
                                                <a class="editEventTypeButton kt-link kt-user-card-v2__name" href="javascript:;" data-eventTypeId="` + row.pushEventType + `">` +
                                                    row.translatedEventType +
                                                `</a>
                                            </div>
                                        </div>`
                                    );

                                }
                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        field: "issuedAt",
                        title: i18n.t("geo_notif_issuedAt"),
                        textAlign: "center",
                        sortable: "desc",
                        template: function(row) {
                            if (row.issuedAt) {
                                const dateStringFormat = commonVueHelper.getDateStringFormatFromLocale( moment().locale() );
                                return moment(row.issuedAt).format(dateStringFormat);
                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        field: "Actions",
                        title: i18n.t("common_actions"),
                        sortable: false,
                        width: 110,
                        overflow: "visible",
                        autoHide: false,
                        textAlign: "center",
                        template: function(row) {
                            const pushEventTypeName = ( row.pushEventTypeName && row.isDefaultPushEventType ? i18n.t("alert_pushEventType_"+row.pushEventTypeName) : row.pushEventTypeName );
                            if (row.isAcknowledged) {
                                return (
                                    `
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_delete") +
                                        `" type="button" data-fulldesc="` + row.assetName + " " + i18n.t("alarm_pressed") + ' \'' + pushEventTypeName + '\'' +
                                        `" data-alarmid="` + row.id +`" class="btn btn-outline-danger btn-sm btn-icon deleteNotificationButton">
                                            <i class="la la-trash"></i>
                                        </button>
                                    `
                                );
                            } else {
                                return (
                                    `
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_acquit") +
                                        `" type="button" data-alarmid="` + row.id + `" class="btn btn-outline-brand btn-sm btn-icon acknowledgeButton">
                                            <i class="la la-check"></i>
                                    </button>
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_delete") +
                                        `" type="button" data-fulldesc="` + row.assetName + " " + i18n.t("alarm_pressed") + ' \'' + pushEventTypeName + '\'' +
                                        `" data-alarmid="` + row.id + `" class="btn btn-outline-danger btn-sm btn-icon deleteNotificationButton">
                                            <i class="la la-trash"></i>
                                    </button>
                                    `
                                );
                            }
                        }
                    }
                ]
            };


            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.siteNotificationsTable) {
                $("#alerts_datatable").KTDatatable().destroy();
                // After destroy set to null
                this.siteNotificationsTable = null;
            }

            // According to source list length, show datatable or empty list icon+text
            if (this.siteAlarms) {
                console.log("Component(PushButtonAlertsHistory)::initKtDataTable - called with " + JSON.stringify(this.siteAlarms.length) + " alarms");
                // There is data => Hide empty list icon+text
                this.isEmptyList = false;
                // Now, we are ready to build the table
                this.siteNotificationsTable = $("#alerts_datatable").KTDatatable(options);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnSiteNotificationsTable();

            } else {

                console.log("Component(PushButtonAlertsHistory)::initKtDataTable - called without alarm");
                // There is no data => Show empty list icon+text
                this.isEmptyList = true;
                this.siteNotificationsTable = null;

            }

        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
